import { apiCall } from '../../../../common/api/api'
import { CatalogoFilters } from '../../type/filter'

export function getCatalogFilterValues(
  catalogKey: string
): Promise<CatalogoFilters> {
  const url = `${process.env.GATSBY_API_URL}/${catalogKey}/filter-values`

  return apiCall(`${url}`, {
    method: 'GET',
    auth: true,
  })
}
