import { createSlice } from '@reduxjs/toolkit'

interface MaintenanceState {
  hasBeenShown: boolean
}

export const initialState: MaintenanceState = {
  hasBeenShown: false,
}
export type MaintenanceStateType = typeof initialState
export const key = 'maintenance'

const maintenanceSlice = createSlice({
  name: 'maintenance',
  initialState, // Corrected property name
  reducers: {
    setShown(state) {
      state.hasBeenShown = true
    },
    reset(state) {
      state.hasBeenShown = false
    },
  },
})

export const { setShown, reset } = maintenanceSlice.actions
export default maintenanceSlice.reducer
