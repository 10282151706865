import {
  actionChannel,
  all,
  call,
  put,
  select,
  take,
  takeLatest,
} from 'redux-saga/effects'
import {
  deleteCesta,
  load,
  productInfo,
  unavailableNeumaticos,
  update,
} from '../../api/cesta'
import { reCalcCestaProducts } from '../../utils/cestaLogic'
import {
  addProduct,
  removeServices,
  updateQuantity,
  updateServices,
} from '../../utils/gtmCestaReporter'
import * as CestaActions from '../actions/cestaActions'
import * as GlobalActions from '../actions/global'
import { loadedCestaState } from '../context'
import { CestaState, Product, Service } from '../reducers/cestaReducer'
import {
  canCestaMontaje,
  needsRemoveNonUnitaryServices,
  selectCesta,
} from '../selectors/cestaSelectors'
import { selectUserInfo } from '../selectors/userSelectors'
import { selectAppSetup } from '../selectors/appSetupSelector'
import { navigate } from 'gatsby'
import route from '../../utils/route'
import { getTaller } from '../../api/taller'
import {
  isCestaAceite,
  isCestaBateria,
  isCestaMoto,
} from '../../../shared/cesta/utils'

const TESTSEGURIDAD_NAVISION_ID = 'TESTSEGURIDAD'
export const RECOGIDA_TALLER_ID = 'RECOGIDA_TALLER'
export const MONTAJE_TALLER_ID = 'MONTAJE_TALLER'

export const RECOGIDA_TALLER_BATERIA_ID = 'RECOGIDA_TALLER_BATERIA'
export const MONTAJE_TALLER_BATERIA_ID = 'MONTAJE_TALLER_BATERIA'
export const SERVICIOS_CON_MATRICULA = ['revision', 'ac'] as string[]
export const RECOGIDA_TALLER_ACEITE_ID = 'RECOGIDA_TALLER_ACEITE'

function* updateCestaServidor() {
  const state = yield select()
  const cestaState = selectCesta(state)
  const userInfo = selectUserInfo(state)
  if (cestaState.products.some((p) => !p.disponibilidad)) {
    for (const p of cestaState.products.filter((p) => !p.disponibilidad)) {
      yield put(CestaActions.removeProduct(p.id_navision, p))
      return null
    }
  } else {
    yield put({ type: CestaActions.UPDATE_CESTA, payload: cestaState })
    try {
      const info = yield update(cestaState, userInfo)
      yield put({ type: CestaActions.UPDATE_CESTA_OK, payload: info })
      return info
    } catch (err) {
      yield put({ type: CestaActions.UPDATE_CESTA_FAILED, payload: err })
    }
  }
}

const generatesMixedOrder = (cestaState: CestaState, newProduct: Product) => {
  const products = cestaState.products
  const tipos = new Set(products.map((product) => product.tipo_producto))
  return !!tipos.size && !tipos.has(newProduct.tipo_producto)
}

function* addToCesta(action: ReturnType<typeof CestaActions.addProduct>) {
  const state = yield select()
  const cestaState = selectCesta(state)
  const userInfo = selectUserInfo(state)
  // We check if there is no logged user and it is fetching a revision
  // so he has previously introduced the CP
  if (!userInfo.postCode) {
    const {
      revision: { postCode },
    } = state
    if (postCode) {
      userInfo.postCode = postCode
    }
  }
  const { allowMixedOrders } = selectAppSetup(state)
  const { idProduct, origin, vehicle, cantidad } = action.payload

  try {
    const info = (yield productInfo(
      idProduct,
      cestaState.cupones,
      userInfo,
      cantidad
    )) as Await<ReturnType<typeof productInfo>>
    // Si acabamos de añadir una revisión o un aire acondicionado le asociamos la matrícula
    // que nos ha llegado.
    info.products = info.products.map((p) =>
      SERVICIOS_CON_MATRICULA.includes(p.tipo_producto) &&
      p.id_navision === idProduct
        ? { ...p, matricula: vehicle.cod_matricula }
        : p
    )
    if (
      !allowMixedOrders &&
      generatesMixedOrder(cestaState, info.products[0])
    ) {
      yield put(
        CestaActions.addProductFailed(
          `no-puedes-anadir-${info.products[0].tipo_producto}`
        )
      )
    } else {
      yield put(CestaActions.addProductOk(info, origin, vehicle))
    }

    const infoAfterUpdate = yield call(updateCestaServidor)
    if (infoAfterUpdate) {
      addProduct(
        infoAfterUpdate.products[infoAfterUpdate.products.length - 1],
        cestaState,
        origin
      )
    }
  } catch (e) {
    yield put(CestaActions.addProductFailed(e.message))
  }
}

function* removeFromCesta(action) {
  const cestaState: CestaState = yield select(selectCesta)
  const product: Product = action.payload.product

  // This reports the removed product as well as any
  // associated per-unit services (only the corresponding quantity)
  updateQuantity(cestaState, product.id_navision, product.cantidad, 'remove')

  // Now we actually remove the product
  const newProducts = cestaState.products.filter(
    (item) => item.id_navision !== action.payload.id_navision
  )

  // This removes the product's per-unit associated services
  let newServices: Service[] = cestaState.services
    .map((service) =>
      service.base_calculo === 'U' &&
      (service.id_site_tipo_vehiculo === null ||
        service.id_site_tipo_vehiculo === product.id_site_tipo_vehiculo)
        ? {
            ...service,
            cantidad: Number(service.cantidad) - parseInt(product.cantidad),
          }
        : service
    )
    .filter((service) => Number(service.cantidad) > 0)
  // This removes non-unitary services depending on the per-vehicle-type services
  const vehicleTypes = new Set(newProducts.map((p) => p.id_site_tipo_vehiculo))
  const sobrantes = newServices.filter(
    (s) =>
      s.base_calculo === 'F' &&
      s.id_site_tipo_vehiculo !== null &&
      !vehicleTypes.has(s.id_site_tipo_vehiculo)
  )

  if (sobrantes.length) {
    // Report removal, but only of the selected ones
    removeServices(sobrantes.filter((s) => s.selected))

    // And then remove them from the services list
    newServices = newServices.filter(
      (s) => !sobrantes.some((ss) => ss.id_navision === s.id_navision)
    )
  }

  // We check if there are remaining tyres in case for category coche
  // We check if there are remaining tyres, camaras or mousses for category moto
  // (if we don't then we will have to remove all services)
  const needsRemoveServices = needsRemoveNonUnitaryServices(
    newProducts,
    newServices
  )
  // And this finally removes (and reports) all non-unitary services
  // when there are no remining tyres.
  if (needsRemoveServices) {
    removeServices(newServices.filter((service) => service.selected))
    newServices = []
  }

  // Recompute cesta and send results to the reducer
  reCalcCestaProducts(newProducts, newServices)
  yield put({
    type: CestaActions.REMOVE_PRODUCT_OK,
    payload: {
      products: newProducts,
      services: newServices,
    },
  })
}

function* maybeResetCesta() {
  const cestaState = yield select(selectCesta)
  if (!cestaState.products.length) {
    yield deleteCesta(cestaState.cestaID)
    yield put({ type: CestaActions.RESET_CESTA })
    return
  }

  yield call(updateCestaServidor)
}

function* reportNewQuantity(action) {
  yield call(updateCestaServidor)
  const cestaState = yield select(selectCesta)
  updateQuantity(
    cestaState,
    action.payload.id_navision,
    action.payload.quantityDifference,
    action.payload.typeOfChange
  )
}

function* reportNewService(action) {
  const state = yield select()
  const cestaState = selectCesta(state)
  updateServices(
    cestaState,
    action.payload.id_navision,
    action.payload.isCurrentlySelected
  )

  yield call(updateCestaServidor)
}

function* reportSwitchService(action) {
  const state = yield select()
  const cestaState = selectCesta(state)
  cestaState.services.forEach((service) => {
    updateServices(
      cestaState,
      service.id_navision,
      service.id_navision === action.payload.id_navision
    )
  })

  yield call(updateCestaServidor)
}

function* startupCesta() {
  if (loadedCestaState.status === 'unloaded') {
    const info = yield load(loadedCestaState.cestaID)
    yield put({ type: CestaActions.LOAD_CESTA_OK, payload: info })
  }
  if (loadedCestaState.status === 'dirty') {
    const info = yield update(loadedCestaState)
    yield put({ type: CestaActions.LOAD_CESTA_OK, payload: info })
  }
  yield call(updateCestaServidor)
}

function* fixRequiredServices() {
  const state = yield select()
  const cestaState = selectCesta(state)

  // Control del test de seguridad
  const testSeguridad = cestaState.services.find(
    (item) => item.id_navision === TESTSEGURIDAD_NAVISION_ID
  )
  const hasTestSeguridad = testSeguridad && testSeguridad.selected
  const hasNeumaticos = cestaState.products.some(
    (item) => item.tipo_producto === 'neumatico' && parseInt(item.cantidad) > 0
  )

  if (cestaState.generaFactura && hasTestSeguridad) {
    yield put(
      CestaActions.checkService(TESTSEGURIDAD_NAVISION_ID, hasTestSeguridad)
    )
  }
  if (
    !cestaState.generaFactura &&
    testSeguridad &&
    !hasTestSeguridad &&
    hasNeumaticos
  ) {
    yield put(
      CestaActions.checkService(TESTSEGURIDAD_NAVISION_ID, hasTestSeguridad)
    )
  }
}

function* fixOptionalServicesMoto() {
  const state = yield select()
  const cestaState = selectCesta(state)

  // Check si la cesta es moto
  const isMotoCesta = isCestaMoto(cestaState.products)

  // Recogida taller service
  const entrega = cestaState.services.find(
    (item) => item.id_navision === RECOGIDA_TALLER_ID
  )
  const entregaSelected = entrega && entrega.selected

  // Montaje taller service
  const montaje = cestaState.services.find(
    (item) => item.id_navision === MONTAJE_TALLER_ID
  )
  const montajeSelected = montaje && montaje.selected
  const canMontaje = canCestaMontaje(cestaState.products)

  if (
    isMotoCesta &&
    canMontaje &&
    montaje &&
    !montajeSelected &&
    entrega &&
    !entregaSelected
  ) {
    // Always pre-check montaje if is available
    yield put(CestaActions.switchService(MONTAJE_TALLER_ID))
  } else if (isMotoCesta && entrega && !entregaSelected && !canMontaje) {
    // If montaje is not available then pre-check recogida
    yield put(CestaActions.switchService(RECOGIDA_TALLER_ID))
  }
}

function* fixOptionalServicesBateria() {
  const state = yield select()
  const cestaState = selectCesta(state)
  const entrega = cestaState.services.find(
    (item) => item.id_navision === RECOGIDA_TALLER_BATERIA_ID
  )
  const entregaSelected = entrega && entrega.selected

  const montaje = cestaState.services.find(
    (item) => item.id_navision === MONTAJE_TALLER_BATERIA_ID
  )
  const montajeSelected = montaje && montaje.selected

  if (
    isCestaBateria(cestaState.products) &&
    !montajeSelected &&
    !entregaSelected
  ) {
    // Always pre-check montaje if there's no option selected
    yield put(CestaActions.switchService(MONTAJE_TALLER_BATERIA_ID))
  }
}

function* fixOptionalServicesAceite() {
  const state = yield select()
  const cestaState = selectCesta(state)

  const entrega = cestaState.services.find(
    (item) => item.id_navision === RECOGIDA_TALLER_ACEITE_ID
  )
  const entregaSelected = entrega && entrega.selected

  const montaje = cestaState.services.find(
    (item) => item.id_navision === RECOGIDA_TALLER_ACEITE_ID
  )
  const montajeSelected = montaje && montaje.selected

  if (
    isCestaAceite(cestaState.products) &&
    !montajeSelected &&
    !entregaSelected
  ) {
    // Always pre-check montaje if there's no option selected
    yield put(CestaActions.switchService(RECOGIDA_TALLER_ACEITE_ID))
  }
}

function* addCodigoPromocional() {
  yield call(updateCestaServidor)
  // call cestaReporter method to add cupon discount. Waiting on specification on how to do it
}

function* sendUnavailableNeumaticos(action) {
  // this calls the unavailable neumatico method to add new missing stock register.
  try {
    yield unavailableNeumaticos(action.payload)
  } catch (e) {
    throw new Error(e)
  }
}

function* addFromExternal(
  action: ReturnType<typeof CestaActions.addFromExternal>
) {
  const channel = yield actionChannel([
    CestaActions.ADD_PRODUCT_OK,
    CestaActions.ADD_PRODUCT_FAILED,
  ])
  try {
    const { productId, codTaller, cantidad } = action.payload
    const taller = yield call(getTaller, codTaller)
    yield put(CestaActions.setSelectedTaller(taller))
    yield put(
      CestaActions.addProduct(
        { id: productId, type: 'otros' },
        'external',
        null,
        cantidad
      )
    )
    const resultAction = yield take(channel)
    if (resultAction.type === CestaActions.ADD_PRODUCT_FAILED) {
      throw new Error(resultAction.payload)
    }
    yield call(updateCestaServidor)

    yield navigate(route('cesta.solicitud'))
  } catch (e) {
    yield navigate(route('index'))
  }
}

function* sagas() {
  return all([
    yield takeLatest(GlobalActions.INIT, startupCesta),
    yield takeLatest(CestaActions.ADD_PRODUCT, addToCesta),
    yield takeLatest(
      CestaActions.ADD_CESTA_SELECTED_TALLER,
      updateCestaServidor
    ),
    yield takeLatest(CestaActions.UPDATE_QUANTITY, reportNewQuantity),
    yield takeLatest(CestaActions.CHECK_SERVICE, reportNewService),
    yield takeLatest(CestaActions.SWITCH_SERVICE, reportSwitchService),
    yield takeLatest(CestaActions.REMOVE_PRODUCT, removeFromCesta),
    yield takeLatest(CestaActions.REMOVE_PRODUCT_OK, maybeResetCesta),
    yield takeLatest(CestaActions.UPDATE_CESTA_OK, fixRequiredServices),
    yield takeLatest(CestaActions.UPDATE_CESTA_OK, fixOptionalServicesMoto),
    yield takeLatest(CestaActions.UPDATE_CESTA_OK, fixOptionalServicesBateria),
    yield takeLatest(CestaActions.UPDATE_CESTA_OK, fixOptionalServicesAceite),
    yield takeLatest(CestaActions.LOAD_CESTA_OK, fixRequiredServices),
    yield takeLatest(CestaActions.ADD_CODIGO_PROMOCION, addCodigoPromocional),
    yield takeLatest(CestaActions.SEND_UNAVAILABLE, sendUnavailableNeumaticos),
    yield takeLatest(CestaActions.ADD_FROM_EXTERNAL_CESTA, addFromExternal),
    yield takeLatest(CestaActions.ADD_CESTA_SELECTED_TIME, updateCestaServidor),
  ])
}

export default sagas
