import {
  CATALOGO_LIST,
  CATALOGO_LIST_FAILED,
  CATALOGO_LIST_OK,
  CATALOGO_LIST_RESET,
} from './catalogoListActions'

import { PaginationResult } from '../../type/pagination'
import { Producto } from '../../producto/producto'
import { PAGE_SIZE } from './types.d'

interface CatalogoState {
  _version: number
  loading: boolean
  error: string | null
  items: Producto[] | null
  pagination: PaginationResult
}

export interface GlobalCatalogState {
  [key: string]: CatalogoState
}

const initialPagination = {
  page: 1,
  total: 1,
  pageSize: PAGE_SIZE,
  pages: 1,
}

export const initialState: CatalogoState = {
  _version: 1,
  loading: false,
  error: null,
  items: [],
  pagination: initialPagination,
}

export const initialGlobalCatalogoState: GlobalCatalogState = {}

export type CatalogoListStateType = typeof initialGlobalCatalogoState
export const key = 'catalogoList'

export const catalogoListReducer = (
  state: GlobalCatalogState = initialGlobalCatalogoState,
  action: any
) => {
  const { payload, meta } = action
  const catalogKey = meta?.catalogKey || key

  switch (action.type) {
    case CATALOGO_LIST:
      return {
        ...state,
        [catalogKey]: {
          ...state[catalogKey],
          loading: true,
        },
      }
    case CATALOGO_LIST_OK:
      return {
        ...state,
        [catalogKey]: {
          loading: false,
          items: payload.data.products,
          pagination: {
            page: payload.data.page,
            pageSize: payload.data.pageSize,
            pages: payload.data.pages,
            total: payload.data.total,
          },
        },
      }
    case CATALOGO_LIST_FAILED:
      return {
        ...state,
        [catalogKey]: {
          loading: false,
          error: payload.error,
        },
      }
    case CATALOGO_LIST_RESET:
      return {
        ...state,
        [catalogKey]: initialState,
      }

    default:
      return state
  }
}
