import { v4 as uuidv4 } from 'uuid'
import { ITime } from '../../api/citaPrevia'
import { CategoriaNeumatico } from '../../types/Neumatico'
import { Promocion as PromocionBanner } from '../../components/promotionBanner/PromotionBanner'
import { SelectedTaller } from '../../types/Taller'
import {
  addProductToCesta,
  purgeCesta,
  reCalcCestaProducts,
} from '../../utils/cestaLogic'
import { localStorage } from '../../utils/storage'
import * as Actions from '../actions/cestaActions'
import { LOGIN_OK, LOGOUT } from '../actions/userActions'
import moment from 'moment'
import {
  CategoriaCesta,
  TipoProductoCesta,
} from '../../../shared/cesta/type/Cesta'
import {
  RECOGIDA_TALLER_ACEITE_ID,
  MONTAJE_TALLER_BATERIA_ID,
  MONTAJE_TALLER_ID,
} from '../sagas/cestaSagas'
import { CategoriaServicio } from '../../types/CategoriaServicio'
import { Vehicle } from '../../types/Vehiculo'
import { Revision } from '../../types/Revision'

export const version = 7
export const key = 'cestaData'

export interface Product {
  id_navision: string
  nombre_producto_es: string
  nombre_producto_ca: string
  nombre_producto_pt: string
  descripcion_es: string
  descripcion_pt: string
  descripcion_ca: string
  tipo_producto: TipoProductoCesta
  mas_info_es: string
  mas_info_ca: string
  mas_info_titulo_es: string
  mas_info_titulo_ca: string
  base_calculo: string
  cantidad: string
  precio: string
  impuesto: string
  descuento: string
  importe: number
  importeSinPromo: number
  promo: string
  ecotasa: string
  disponibilidad: number
  id_site_tipo_vehiculo: number | null
  runflat: number | null
  servicios: Service[]
  fechaEntrega: string
  comprobacionFechaEntrega: string
  codAlmacen: string
  codProveedor: string
  marcaje?: string
  matricula?: string
  marca: string
  clasificacion: CategoriaNeumatico
  promocion: Promocion
  banner: PromocionBanner | null
  categoria: CategoriaCesta
}

export interface Promocion {
  id_site_promocion_cesta: number | null
  tipo:
    | 'individual-2aX'
    | 'individual-4x3'
    | 'individual-porcentaje'
    | 'individual-absoluto'
    | 'global-porcentaje'
    | 'global-absoluto'
    | 'seguro'
    | 'alineacion'
    | 'nitrogeno'
    | 'packinvierno'
    | 'kitur'
    | 'kit4x4'
    | 'kitcta'
    | 'montaje'
  importePromocion: number
  descripcion_es: string | null // erase these null, for now we leave due to branch incoherence
  descripcion_ca: string | null
  descripcion_pt: string | null
  importe_cupon: number
  fecha_limite: string
}

export interface Service {
  id_navision: string
  nombre_producto_es: string
  nombre_producto_ca: string
  descripcion_es: string
  descripcion_ca: string
  tipo_producto: 'servicio'
  mas_info_es: string
  mas_info_ca: string
  mas_info_titulo_es: string
  mas_info_titulo_ca: string
  base_calculo: 'U' | 'F'
  cantidad: number | string
  impuesto: string
  descuento: string
  importe: number
  ecotasa: number
  precio: string
  promo: string
  orden: number
  id_site_tipo_vehiculo: number
  obligatorio: number
  recomendado: number
  extra_servicio: number
  descuentoAsociado: string
  selected: boolean
  tipoProductoAsociado?: string
  categoriaServicio?: CategoriaServicio | null
  banner?: PromocionBanner | null
  categoria: CategoriaCesta
  promocion: Promocion
}

export interface CestaDiscount {
  tipo: string | null
  importe_cupon: number
  descripcion_ca: string | null
  descripcion_es: string | null
  descripcion_pt: string | null
  codigo_promocion: string | null
  id_site_promocion_cesta: number
  tipo_producto: string | null
}

export const NULL_DISCOUNT: CestaDiscount = {
  tipo: null,
  importe_cupon: 0,
  descripcion_ca: null,
  descripcion_es: null,
  descripcion_pt: null,
  codigo_promocion: null,
  id_site_promocion_cesta: null,
  tipo_producto: null,
}

interface CuponError {
  message: 'NO_APPLY_CUPON' | 'UNEXISTING_CUPON'
  cupon: string
}

export enum CestaOrigin {
  Internal = 'internal',
  External = 'external',
}
type CestaOriginType = CestaOrigin.Internal | CestaOrigin.External

export const initialState = {
  _version: version,
  status: 'new' as 'new' | 'unloaded' | 'dirty' | 'ok',
  loading: false as boolean,
  cestaID: '' as string,
  products: [] as Product[],
  services: [] as Service[],
  codigoPostalIntroducido: '' as string,
  selectedTaller: {} as SelectedTaller,
  selectedDate: null as ITime,
  orderNumber: '' as string,
  matricula: null as string | null,
  step: 0,
  newVehicleData: null as Vehicle | null, // populated when a new user comes from revisiones or ac
  paymentType: null as 'total' | 'parcial' | null,
  error: null as string | null,
  generaFactura: false,
  cupones: [] as string[],
  cuponError: null as CuponError | null,
  displayCouponBox: true as boolean,
  discount: NULL_DISCOUNT,
  origin: CestaOrigin.Internal as CestaOriginType,
  searchToken: null as string | null,
  selectedRevision: {} as Revision,
}

export type CestaState = typeof initialState

export const createCesta = () => {
  const cestaID = uuidv4()
  localStorage.setItem(key, cestaID)
  return { ...initialState, cestaID }
}

export const reducer = (
  state: CestaState = initialState,
  action
): CestaState => {
  switch (action.type) {
    case LOGOUT:
      return createCesta()
    case Actions.INIT_CESTA:
      return createCesta()
    case Actions.SET_STEP:
      return {
        ...state,
        step: action.payload,
      }
    case Actions.REMOVE_PRODUCT_OK:
      return {
        ...state,
        products: action.payload.products,
        services: action.payload.services,
      }
    case Actions.UPDATE_QUANTITY: {
      const newProducts = state.products.map((p) =>
        p.id_navision === action.payload.id_navision
          ? {
              ...p,
              cantidad: action.payload.quantity,
            }
          : p
      )
      const newServices = [...state.services]
      reCalcCestaProducts(newProducts, newServices)
      return {
        ...state,
        products: newProducts,
        services: newServices,
      }
    }
    case Actions.CHECK_SERVICE:
      state.services = state.services.map((p) =>
        p.id_navision === action.payload.id_navision
          ? {
              ...p,
              selected: !p.selected,
            }
          : p
      )
      reCalcCestaProducts(state.products, state.services)
      return {
        ...state,
      }
    case Actions.SWITCH_SERVICE: {
      const newState = {
        ...state,
        products: state.products.map((product) => ({
          ...product,
          servicios: product.servicios.map((servicio) => ({
            ...servicio,
            selected:
              servicio.categoriaServicio === CategoriaServicio.Montaje &&
              [
                MONTAJE_TALLER_ID,
                MONTAJE_TALLER_BATERIA_ID,
                RECOGIDA_TALLER_ACEITE_ID,
              ].includes(action.payload.id_navision),
          })),
        })),
        services: state.services.map((service) => ({
          ...service,
          selected: service.id_navision === action.payload.id_navision,
        })),
      }
      reCalcCestaProducts(newState.products, newState.services)
      return newState
    }
    case Actions.ADD_PRODUCT_OK: {
      const { products, services, vehicle } = action.payload

      let newState = {
        loading: false,
        ...state,
        products: [...state.products],
        services: [...state.services],
      }

      if (vehicle) {
        // We allow only 1 Vehicle in cesta, so if we have info about another vehicle before
        // just remove all cesta
        if (
          newState.newVehicleData &&
          newState.newVehicleData.cod_matricula !== vehicle.cod_matricula
        ) {
          newState = { ...createCesta(), products: [], services: [] }
        }
        // Then set data of new vehicle in cestaState
        newState = {
          ...newState,
          newVehicleData: vehicle,
          matricula: vehicle.matricula,
        }
      }

      if (products.length === 1) {
        newState = purgeCesta(newState, products[0])
      }

      addProductToCesta(newState, products, services)
      return newState
    }
    case Actions.ADD_PRODUCT:
    case Actions.UPDATE_CESTA:
    case Actions.LOAD_CESTA:
      return {
        ...state,
        loading: true,
      }
    case Actions.UPDATE_CESTA_OK: {
      const { payload } = action
      return {
        ...state,
        loading: false,
        products: [...payload.products],
        services: [...payload.services],
        cupones: payload.cupones,
        cuponError: payload.cuponError,
        displayCouponBox: payload.displayCouponBox,
        generaFactura: payload.generaFactura,
        discount: payload.discount,
      }
    }
    case Actions.LOAD_CESTA_OK:
      return {
        ...state,
        loading: false,
        status: 'ok',
        discount: { ...action.payload.discount },
        products: action.payload.products,
        services: action.payload.services,
        generaFactura: action.payload.generaFactura,
      }
    case LOGIN_OK: {
      if (!action.payload.cesta) {
        return state
      }
      const cestaID = action.payload.cesta.cestaID || state.cestaID
      localStorage.setItem(key, cestaID)
      return {
        ...state,
        ...action.payload.cesta,
        cestaID,
      }
    }
    case Actions.ADD_CESTA_SELECTED_TIME_FAILED:
    case Actions.ADD_PRODUCT_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case Actions.LOAD_CESTA_FAILED:
      // Show error? We start anew...
      return createCesta()
    case Actions.ADD_CESTA_SELECTED_TALLER:
      return {
        ...state,
        selectedTaller: { ...action.payload.selectedTaller },
        codigoPostalIntroducido:
          state.codigoPostalIntroducido ||
          action.payload.selectedTaller.cpostal,
      }
    case Actions.ADD_CESTA_SELECTED_DATE:
      return {
        ...state,
        selectedDate: action.payload.selectedDate,
      }
    case Actions.ADD_CESTA_SELECTED_CP:
      return {
        ...state,
        codigoPostalIntroducido: action.payload.cp,
      }
    case Actions.UPDATE_PRODUCT:
    case Actions.ADD_CESTA_SELECTED_TIME: {
      /* const { selectedProducts } = action.payload
      const selectedProductsMap = selectedProducts.reduce(
        (product) => ([product.id_navision] = product),
        {}
      )
      const products = state.products.map((product) => {
        const sp = selectedProductsMap[product.id_navision]
        return sp
          ? {
              ...product,
              codAlmacen: sp.codAlmacen,
              codProveedor: sp.codProveedor,
              fechaEntrega: sp.fechaEntrega,
              comprobacionFechaEntrega: moment().format('DD-MM-YYYY'),
            }
          : product
      })
      return {
        ...state,
        products,
        loading: true,
      } */
      const { selectedProducts } = action.payload
      const newProducts = state.products.map((product) => {
        const sp = selectedProducts.find(
          (sps) => sps.id_navision === product.id_navision
        )
        if (sp) {
          product.codAlmacen = sp.codAlmacen
          product.codProveedor = sp.codProveedor
          product.fechaEntrega = sp.fechaEntrega
          product.comprobacionFechaEntrega = moment().format('DD-MM-YYYY')
        }
        return product
      })
      return {
        ...state,
        products: newProducts,
        loading: true,
      }
    }
    case Actions.ADD_CESTA_SELECTED_TIME_OK:
      return {
        ...state,
        loading: false,
      }
    case Actions.ADD_CESTA_ORDER_NUMBER:
      return {
        ...state,
        orderNumber: action.payload.orderNumber,
      }
    case Actions.ADD_NEWVEHICLE_DATA:
      return {
        ...state,
        newVehicleData: action.payload.newVehicleData,
      }
    case Actions.ADD_MATRICULA:
      return {
        ...state,
        matricula: action.payload.matricula,
      }
    case Actions.ADD_CODIGO_PROMOCION:
      return {
        ...state,
        cupones: [
          ...new Set([...state.cupones, action.payload.codigo_promocion]),
        ],
        discount: {
          ...state.discount,
        },
      }
    case Actions.ADD_PAYMENT_TYPE:
      return {
        ...state,
        paymentType: action.payload.paymentType,
      }
    case Actions.RESET_ERROR:
      return {
        ...state,
        error: null,
      }

    case Actions.RESET_CESTA:
      return {
        ...createCesta(),
        error: null,
      }
    case Actions.ADD_SEARCH_TOKEN:
      return {
        ...state,
        searchToken: action.payload.token,
      }
    case Actions.ADD_CESTA_SELECTED_REVISION:
      return {
        ...state,
        selectedRevision: { ...action.payload.selectedRevision },
      }
    default:
      return state
  }
}
