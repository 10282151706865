import { CatalogoSearchParams } from '../../type/filter'
import { GetProductsList } from './types'

export const CATALOGO_LIST = 'CATALOGO::LIST'
export const CATALOGO_LIST_OK = 'CATALOGO::LIST_OK'
export const CATALOGO_LIST_FAILED = 'CATALOGO::LIST_FAILED'
export const CATALOGO_LIST_RESET = 'CATALOGO::LIST_RESET'

export function catalogoListAction<T>(
  key: string,
  params: CatalogoSearchParams<T>
) {
  return {
    type: CATALOGO_LIST,
    payload: params,
    meta: { catalogKey: key },
  }
}

export function catalogoListOkAction<T>(key: string, data: GetProductsList<T>) {
  return {
    type: CATALOGO_LIST_OK,
    payload: { data },
    meta: { catalogKey: key },
  }
}

export function catalogoListFailedAction(key: string, message: string) {
  return {
    type: CATALOGO_LIST_FAILED,
    payload: { error: message },
    meta: { catalogKey: key },
  }
}

export function catalogoListResetAction(key: string) {
  return { type: CATALOGO_LIST_RESET, meta: { catalogKey: key } }
}
