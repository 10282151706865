import { CestaState, Product, Service } from '../context/reducers/cestaReducer'
import { CategoriaCesta } from '../../shared/cesta/type/Cesta'
import {
  hasMontajeBateria,
  hasMontajeNeumatico,
} from '../context/selectors/cestaSelectors'
import route from './route'
import {
  isCestaBateria,
  isCestaCatalogo,
  isCestaMoto,
} from '../../shared/cesta/utils'
import { CategoriaServicio } from '../types/CategoriaServicio'

const calculateDiscount = (value: number, discount: string) => {
  const d = parseFloat(discount)
  const discountFactor = d > 0 ? 1 - d / 100 : 1
  return Math.round(value * discountFactor * 100) / 100
}

export const calculateImporte = (
  producto: {
    precio: string
    cantidad: string
    descuento: string
    promo: string
    impuesto: string
    ecotasa?: string
  },
  opciones: {
    ecotasa: boolean
    promo: boolean
    impuesto: boolean
  }
) => {
  const params = {
    ecotasa: false,
    promo: true,
    impuesto: true,
    ...opciones,
  }
  let importe = parseFloat(producto.precio) * parseInt(producto.cantidad)

  // El descuento lo aplicamos siempre
  importe = calculateDiscount(importe, producto.descuento)

  // Aplicamos la promo si hace falta
  if (params.promo) {
    if (typeof producto.promo === 'undefined') {
      throw new Error('Hay que indicar el porcentaje de promo del producto')
    }
    importe = calculateDiscount(importe, producto.promo)
  }

  // Ahora aplicamos la ecotasa si toca
  if (params.ecotasa) {
    if (typeof producto.ecotasa === 'undefined') {
      throw new Error('Hay que indicar la ecotasa')
    }
    importe = Math.round((importe + parseFloat(producto.ecotasa)) * 100) / 100
  }

  // Y finalmente el impuesto
  if (params.impuesto) {
    if (typeof producto.impuesto === 'undefined') {
      throw new Error('Hay que indicar el impuesto')
    }
    importe =
      Math.round(importe * (1 + parseFloat(producto.impuesto) / 100) * 100) /
      100
  }
  return importe
}

const calculateServiceAmount = (
  serv: Service,
  products: Product[],
  productQuantity?: string
) => {
  let cantidad = '1'
  if (serv.base_calculo === 'U') {
    // calculates per unit
    if (productQuantity) {
      cantidad = productQuantity
    } else if (
      serv.tipoProductoAsociado === 'neumatico' &&
      serv.id_site_tipo_vehiculo !== null
    ) {
      // to know quantity, we use tipoProductoAsociado field
      // if neumatico count number of tyres per id_site_tipo_vehiculo
      cantidad = products
        .filter(
          (cp) =>
            cp.tipo_producto === serv.tipoProductoAsociado &&
            cp.id_site_tipo_vehiculo === serv.id_site_tipo_vehiculo &&
            cp.disponibilidad === 1
        )
        .reduce((acc, obj) => acc + Number(obj.cantidad), 0)
        .toString()
    } else {
      cantidad = products
        .filter(
          (cp) =>
            cp.tipo_producto === serv.tipoProductoAsociado &&
            cp.disponibilidad === 1
        )
        .reduce((acc, obj) => acc + Number(obj.cantidad), 0)
        .toString()
    }
    serv.cantidad = productQuantity || cantidad
  }

  serv.importe = calculateImporte(
    {
      precio: serv.precio,
      cantidad,
      descuento: serv.descuento,
      impuesto: serv.impuesto,
      promo: serv.descuentoAsociado, // apply discount associated to service
    },
    {
      ecotasa: false,
      promo: true,
      impuesto: true,
    }
  )
}

export const reCalcCestaProducts = (
  products: Product[],
  services: Service[]
) => {
  let totalProducts = 0.0
  let totalServices = 0.0

  products
    .filter((p) => p.disponibilidad === 1)
    .forEach((prod) => {
      let totalProdServ = 0.0

      prod.importe = calculateImporte(prod, {
        ecotasa: false,
        promo: true,
        impuesto: true,
      })
      prod.importeSinPromo = calculateImporte(prod, {
        ecotasa: false,
        promo: false,
        impuesto: true,
      })

      prod.servicios.forEach((prodServ) => {
        if (prodServ.id_navision === 'eco_nav') {
          prodServ.precio = prod.ecotasa
        }
        calculateServiceAmount(prodServ, products, prod.cantidad)
        if (prodServ.categoriaServicio !== CategoriaServicio.Montaje) {
          totalProdServ += prodServ.importe
        }
      })
      // add to totalProductos producto.importe + SUM(productos.servicios.importe)
      totalProducts +=
        Math.round(
          (prod.importe + totalProdServ - prod.promocion.importePromocion) * 100
        ) / 100
    })

  const montajeService = services.find(
    (service) => service.id_navision === 'MONTAJE_TALLER'
  )
  if (montajeService) {
    montajeService.precio = String(
      products.reduce((acc, product) => {
        const montaje = product.servicios.find(
          (serv) => serv.categoriaServicio === CategoriaServicio.Montaje
        )
        if (montaje) {
          return acc + Number(montaje.precio) * Number(montaje.cantidad)
        }
        return acc
      }, 0)
    )
  }
  // Look for general services
  services.forEach((serv) => {
    calculateServiceAmount(serv, products)

    // add to totalServicios only selected services
    if (serv.selected) {
      totalServices += serv.importe
    }
  })

  const totalProductos = Math.round(totalProducts * 100) / 100
  const total = Math.round((totalProductos + totalServices) * 100) / 100
  return { totalProductos, total }
}

export const addProductToCesta = (
  cestaData: CestaState,
  newProducts: Product[],
  newServices: Service[]
) => {
  // check products
  // take into account that only one revision is possible
  newProducts.forEach((np) => {
    const existProduct = cestaData.products.find(
      (p) => p.id_navision === np.id_navision
    )

    if (existProduct && isCestaCatalogo(newProducts)) {
      const productIndex = cestaData.products.indexOf(existProduct)
      if (productIndex !== -1) {
        cestaData.products.splice(productIndex, 1, np) // productos catalogo con selección de cantidad queremos sobreescribir la cantidad si ya existe el producto en cesta
      }
      return
    }
    if (!existProduct && np.tipo_producto === 'revision') {
      let previousRevisionIndex
      cestaData.products.find((p, i) => {
        if (p.tipo_producto === 'revision') {
          previousRevisionIndex = i
        }
        return p.tipo_producto === 'revision'
      })
      if (previousRevisionIndex !== undefined) {
        cestaData.products.splice(previousRevisionIndex, 1)
      }
      cestaData.products.push(np)
    } else if (!existProduct) {
      // When we add a new moto product we should check if the cesta has montaje enabled to add the new product with the
      // service selected
      if (
        (isCestaMoto(newProducts) && hasMontajeNeumatico(cestaData)) ||
        (isCestaBateria(newProducts) && hasMontajeBateria(cestaData))
      ) {
        const ns = np.servicios.find(
          (s) => s.categoriaServicio === CategoriaServicio.Montaje
        )
        if (ns) {
          ns.selected = true
        }
      }

      cestaData.products.push(np)
    }
  })

  // check services
  newServices.forEach((ns) => {
    const existService = cestaData.services.find(
      (p) => p.id_navision === ns.id_navision
    )
    if (!existService) {
      cestaData.services.push(ns)
    }
  })
  // recalc with new info
  return reCalcCestaProducts(cestaData.products, cestaData.services)
}

export const purgeCestaCategories = (
  cestaData: CestaState,
  category: CategoriaCesta
) => {
  return {
    ...cestaData,
    products: [
      ...cestaData.products.filter((product) => product.categoria === category),
    ],
    services: [
      ...cestaData.services.filter((service) => service.categoria === category),
    ],
  }
}

// Define the product types that should only allow similar types in the cesta
const restrictedTypes = ['bateria', 'aceite']

export const purgeCestaRestrictedTypes = (
  cestaData: CestaState,
  typeToKeep: string
) => {
  return {
    ...cestaData,
    products: [
      ...cestaData.products.filter(
        (product) =>
          product.tipo_producto === typeToKeep &&
          !restrictedTypes.includes(product.tipo_producto)
      ),
    ],
    services: [
      ...cestaData.services.filter(
        (service) => service.tipoProductoAsociado === typeToKeep
      ),
    ],
  }
}

export const purgeCesta = (cesta: CestaState, product: Product) => {
  //  We allow only 1 category product in cesta (coche | moto)
  if (cesta.products.some((p) => p.categoria !== product.categoria)) {
    cesta = purgeCestaCategories(cesta, product.categoria)
  }
  //  We allow only one restrictedType in cesta, so if we add a restrictedType just remove other products
  if (restrictedTypes.includes(product.tipo_producto)) {
    cesta = purgeCestaRestrictedTypes(cesta, product.tipo_producto)
  }
  //  We allow only restrictedType in cesta, so if we add other kind of product we remove restrictedType
  if (
    !restrictedTypes.includes(product.tipo_producto) &&
    cesta.products.some((p) => restrictedTypes.includes(p.tipo_producto))
  ) {
    cesta = purgeCestaRestrictedTypes(cesta, product.tipo_producto)
  }
  return cesta
}

export const isCestaExternal = () => {
  if (process.env.GATSBY_WEB !== 'rodi') {
    return false
  }
  const path = typeof window !== 'undefined' && window.location.pathname
  const externalPath = route('proxy.cesta.anadir-producto')
  return new RegExp(externalPath).test(path)
}
