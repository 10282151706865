import { all, put, takeLatest, call } from 'redux-saga/effects'
import { t } from '../../../../common/i18n'
import * as CatalogoListAction from './catalogoListActions'
import {
  catalogoListFailedAction,
  catalogoListOkAction,
} from './catalogoListActions'
import { GetProductsList } from './types'
import { getCatalogData } from './catalogoListApi'

export function* getCatalogoData<T>(
  action: ReturnType<typeof CatalogoListAction.catalogoListAction>
) {
  const { payload, meta } = action
  const { catalogKey } = meta

  try {
    const catalogoData: GetProductsList<T> = yield call(
      getCatalogData,
      catalogKey,
      payload
    )
    yield put(catalogoListOkAction<T>(catalogKey, catalogoData))
  } catch (err) {
    yield put(catalogoListFailedAction(catalogKey, t(`No hay ${catalogKey}`)))
  }
}

function* sagas() {
  return all([
    yield takeLatest(CatalogoListAction.CATALOGO_LIST, getCatalogoData),
  ])
}

export default sagas
