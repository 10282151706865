import { apiCall } from './api'

const baseUrl = `${process.env.GATSBY_API_URL}`

interface RegisterConsultaFechaHora {
  dispositivo: 'MOBILE' | 'TABLET' | 'DESKTOP'
  codigo_taller: string
  origen: 'pedido' | 'cita_previa'
  email: string
  telefono: string
  codigo_postal: string
  fecha_disponibilidad: string
}

export function registerConsultaFechaHora(
  data: RegisterConsultaFechaHora
): Promise<{ message: string }> {
  const dataRegistro = {
    ...data,
    user_agent: navigator.userAgent,
  }
  return apiCall(`${baseUrl}/consulta-fecha-hora`, {
    method: 'POST',
    body: JSON.stringify(dataRegistro),
  })
}

export interface RegistroLeadMatricula {
  matricula: string
  aceite_escogido: string
  dispositivo: 'MOBILE' | 'TABLET' | 'DESKTOP'
  id_site_usuario: string | null
  postCode: string
}
export const registroLeadMatricula = (
  data: RegistroLeadMatricula
): Promise<{ message: string }> => {
  const dataRegistroLeadMatricula = {
    ...data,
    user_agent: navigator.userAgent,
  }
  return apiCall(`${baseUrl}/registro-lead-matricula-revision`, {
    method: 'POST',
    body: JSON.stringify(dataRegistroLeadMatricula),
  })
}
