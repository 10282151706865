import { all, call, put, takeLatest } from 'redux-saga/effects'
import { t } from '../../../../common/i18n'
import * as CatalogoFilterValuesAction from './catalogoFilterValuesAction'
import {
  CATALOGO_FILTER_VALUES,
  catalogoFilterValuesFailedAction,
  catalogoFilterValuesOkAction,
} from './catalogoFilterValuesAction'
import { CatalogoFilters } from '../../type/filter'
import { getCatalogFilterValues } from './catalogoFilterApi'

export function* getCatalogoFilterValues(
  action: ReturnType<
    typeof CatalogoFilterValuesAction.catalogoFilterValuesAction
  >
) {
  const { meta } = action
  const { catalogKey } = meta

  try {
    const catalogoData: CatalogoFilters = yield call(
      getCatalogFilterValues,
      catalogKey
    )
    yield put(catalogoFilterValuesOkAction(catalogKey, catalogoData))
  } catch (err) {
    yield put(
      catalogoFilterValuesFailedAction(catalogKey, t(`No hay ${catalogKey}`))
    )
  }
}

function* sagas() {
  return all([
    yield takeLatest(CATALOGO_FILTER_VALUES, getCatalogoFilterValues),
  ])
}

export default sagas
