import { CatalogoFilters } from '../../type/filter'

export const CATALOGO_FILTER_VALUES = 'CATALOGO_FILTER_VALUES::GET'
export const CATALOGO_FILTER_VALUES_OK = 'CATALOGO_FILTER_VALUES::GET_OK'
export const CATALOGO_FILTER_VALUES_FAILED =
  'CATALOGO_FILTER_VALUES::GET_FAILED'
export const CATALOGO_FILTER_VALUES_RESET = 'CATALOGO_FILTER_VALUES::GET_RESET'

export function catalogoFilterValuesAction(key: string) {
  return {
    type: CATALOGO_FILTER_VALUES,
    meta: { catalogKey: key },
  }
}

export function catalogoFilterValuesOkAction(
  key: string,
  data: CatalogoFilters
) {
  return {
    type: CATALOGO_FILTER_VALUES_OK,
    payload: { data },
    meta: { catalogKey: key },
  }
}

export function catalogoFilterValuesFailedAction(key: string, message: string) {
  return {
    type: CATALOGO_FILTER_VALUES_FAILED,
    payload: { error: message },
    meta: { catalogKey: key },
  }
}
export function catalogoFilterValuesResetAction(key: string) {
  return { type: CATALOGO_FILTER_VALUES_RESET, meta: { catalogKey: key } }
}
