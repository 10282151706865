import * as Actions from './catalogoFilterValuesAction'
import { CatalogoFilters } from '../../type/filter'

export const version = 1
export const key = 'catalogoFilterValues'

interface CatalogoFilterValuesState {
  _version: number
  loading: boolean
  error: string | null
  values: CatalogoFilters
}

export const initialFilterValuesState: CatalogoFilterValuesState = {
  _version: version,
  loading: false,
  error: null,
  values: null,
}

export interface GlobalCatalogFilterValuesState {
  [key: string]: CatalogoFilterValuesState
}

export const initialGlobalCatalogoFilterValuesState: GlobalCatalogFilterValuesState =
  {}

export type CatalogoFilterValuesStateType =
  typeof initialGlobalCatalogoFilterValuesState

export const catalogoFilterValuesReducer = (state = {}, action) => {
  const { meta } = action
  const catalogKey = meta?.catalogKey || key

  switch (action.type) {
    case Actions.CATALOGO_FILTER_VALUES:
      return {
        ...state,
        [catalogKey]: {
          ...state[catalogKey],
          loading: true,
        },
      }
    case Actions.CATALOGO_FILTER_VALUES_OK:
      return {
        ...state,
        [catalogKey]: {
          loading: false,
          values: {
            ...state[catalogKey].values,
            ...action.payload.data,
          },
        },
      }
    case Actions.CATALOGO_FILTER_VALUES_FAILED:
      return {
        ...state,
        [catalogKey]: {
          loading: false,
          error: action.payload.error,
        },
      }
    case Actions.CATALOGO_FILTER_VALUES_RESET:
      return {
        ...state,
        [catalogKey]: initialFilterValuesState,
      }

    default:
      return state
  }
}
