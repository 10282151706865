import { ProductInfo } from '../context/actions/cestaActions'
import { CestaState, Product, Service } from '../context/reducers/cestaReducer'
import { selectImporteTotal } from '../context/selectors/cestaSelectors'
import dataLayer from '../helpers/functions/dataLayer'
import { AddProductOrigin } from '../../shared/cesta/type/Cesta'
import { getShortCompany } from './getCompany'
import { UserState } from '../context/reducers/userReducer'

const errorHandler = (cb: Function) => {
  try {
    cb()
  } catch (err) {
    console.error(err.message)
  }
}

const getMarca = (nombre: string) => nombre.split(' ')[0]

const productDescriptor = (product: Product, quantity: number | string) => {
  return {
    name: product.nombre_producto_es,
    id: product.id_navision,
    price:
      Math.round(
        ((product.importe - product.promocion.importePromocion) /
          parseInt(product.cantidad)) *
          100
      ) / 100,
    brand: getMarca(product.nombre_producto_es),
    category: 'Neumáticos',
    quantity: Number(quantity),
  }
}

const otherProductsDescriptor = (product: Product, quantity: string) => {
  let category
  switch (product.tipo_producto) {
    case 'ac':
      category = 'aireacondicionado'
      break
    case 'kito1':
      category = 'kito1'
      break
    case 'kito2':
      category = 'kito2'
      break
    case 'bateria':
      category = 'bateria'
      break
    case 'amortiguador':
      category = 'amortiguador'
      break
    case 'preitv':
      category = 'preitv'
      break
    case 'packinvierno':
      category = 'packinvierno'
      break
    case 'ozono':
      category = 'ozono'
      break
    case 'aceite':
      category = 'aceite'
      break
  }
  let price =
    Math.round((product.importe / parseInt(product.cantidad)) * 100) / 100
  if (product.promocion.importePromocion) {
    price =
      Math.round(
        (product.importe -
          product.promocion.importePromocion / parseInt(product.cantidad)) *
          100
      ) / 100
  }
  return {
    name: product.nombre_producto_es,
    id: product.id_navision,
    price,
    brand: '-',
    category,
    quantity,
  }
}

const revisionDescriptor = (product: Product, quantity: string) => ({
  name: product.nombre_producto_es,
  id: product.id_navision,
  price: Math.round((product.importe / parseInt(product.cantidad)) * 100) / 100,
  brand: product.descripcion_es,
  category: 'Revisiones',
  quantity,
})

const serviceDescriptor = (service: Service, quantity: number | string) => ({
  name: service.nombre_producto_es,
  id: service.id_navision,
  price: Math.round((service.importe / Number(service.cantidad)) * 100) / 100,
  brand: getShortCompany(),
  category: 'Servicio',
  quantity: Number(quantity),
})

// select neumatico and add product are similar but are specified in different documents (v5 vs v4)
export const selectNeumatico = (product: ProductInfo) => {
  errorHandler(() => {
    switch (product.type) {
      case 'neumatico':
        return dataLayer.push({
          event: 'elegir_neumaticos',
          categoria_seleccionada: product.categoria.toUpperCase(),
          marca_seleccionada: product.marca.toUpperCase(),
        })
      case 'camara':
        return dataLayer.push({
          event: 'elegir_camara_mousse',
          marca_seleccionada: product.marca.toUpperCase(),
          tipo: product.type,
        })
      default:
        break
    }
  })
}

export const addProduct = (
  product: Product,
  cestaState: CestaState,
  origin: AddProductOrigin
): void => {
  errorHandler(() => {
    const list =
      origin === 'navision'
        ? 'recomendados'
        : origin === 'database'
        ? 'all'
        : undefined
    const actionField = list
      ? {
          actionField: {
            list,
          },
        }
      : {}
    switch (product.tipo_producto) {
      case 'neumatico':
        dataLayer.push({
          event: 'addToCart',
          ecommerce: {
            ...actionField,
            currencyCode: 'EUR',
            add: {
              products: [
                productDescriptor(product, product.cantidad),
                ...product.servicios.map((servicio) =>
                  serviceDescriptor(servicio, product.cantidad)
                ),
                ...cestaState.services
                  .filter(
                    (service) =>
                      service.selected &&
                      service.base_calculo === 'U' &&
                      (service.id_site_tipo_vehiculo ===
                        product.id_site_tipo_vehiculo ||
                        service.id_site_tipo_vehiculo === null)
                  )
                  .map((service) =>
                    serviceDescriptor(service, product.cantidad)
                  ),
              ].filter((item) => !!item),
            },
          },
        })
        break
      case 'revision':
        dataLayer.push({
          event: 'addToCart',
          ecommerce: {
            currencyCode: 'EUR',
            add: {
              products: [revisionDescriptor(product, '1')],
            },
          },
        })
        dataLayer.push({
          event: 'elegir_revision',
          revision_plan: product.nombre_producto_es,
        })
        break
      case 'ac':
        dataLayer.push({
          event: 'addToCart',
          ecommerce: {
            currencyCode: 'EUR',
            add: {
              products: [otherProductsDescriptor(product, '1')],
            },
          },
        })
        dataLayer.push({
          event: 'promo_aire_click',
        })
        break
    }
  })
}

// updateCodigoPromocional(discount: CestaDiscount) {
//   if (discount.id_site_promocion_cesta) {
//     dataLayer.push({
//       event: ''
//     })
//   }
// }

export const updateQuantity = (
  cestaState: CestaState,
  id: string,
  quantityDifference: string,
  typeOfChange: 'add' | 'remove'
) => {
  errorHandler(() => {
    const product = cestaState.products.find((item) => item.id_navision === id)
    switch (typeOfChange) {
      case 'add':
        dataLayer.push({
          event: 'addToCart',
          ecommerce: {
            currencyCode: 'EUR',
            add: {
              products: [
                product.tipo_producto === 'neumatico'
                  ? productDescriptor(product, quantityDifference)
                  : revisionDescriptor(product, quantityDifference),
                ...product.servicios.map((servicio) =>
                  serviceDescriptor(servicio, quantityDifference)
                ),
                ...cestaState.services
                  .filter(
                    (service) =>
                      service.selected &&
                      service.base_calculo === 'U' &&
                      (service.id_site_tipo_vehiculo ===
                        product.id_site_tipo_vehiculo ||
                        service.id_site_tipo_vehiculo === null)
                  )
                  .map((service) =>
                    serviceDescriptor(service, quantityDifference)
                  ),
              ].filter((item) => !!item),
            },
          },
        })
        break
      case 'remove':
        dataLayer.push({
          event: 'removeFromCart',
          ecommerce: {
            currencyCode: 'EUR',
            remove: {
              products: [
                product.tipo_producto === 'neumatico'
                  ? productDescriptor(product, quantityDifference)
                  : revisionDescriptor(product, quantityDifference),
                ...product.servicios.map((servicio) =>
                  serviceDescriptor(servicio, quantityDifference)
                ),
                ...cestaState.services
                  .filter(
                    (service) =>
                      service.selected &&
                      service.base_calculo === 'U' &&
                      (service.id_site_tipo_vehiculo ===
                        product.id_site_tipo_vehiculo ||
                        service.id_site_tipo_vehiculo === null)
                  )
                  .map((service) =>
                    serviceDescriptor(service, quantityDifference)
                  ),
              ].filter((item) => !!item),
            },
          },
        })
        break
    }
  })
}

export const removeServices = (services: Service[]) => {
  dataLayer.push({
    event: 'removeFromCart',
    ecommerce: {
      currencyCode: 'EUR',
      remove: {
        products: services.map((service) =>
          serviceDescriptor(service, service.cantidad)
        ),
      },
    },
  })
}

export const updateServices = (
  cestaState: CestaState,
  id: string,
  isCurrentlySelected: boolean
) => {
  errorHandler(() => {
    const service = cestaState.services.find((item) => item.id_navision === id)
    if (service) {
      if (!isCurrentlySelected) {
        dataLayer.push({
          event: 'addToCart',
          ecommerce: {
            currencyCode: 'EUR',
            add: {
              products: [serviceDescriptor(service, service.cantidad)],
            },
          },
        })
      }
      if (isCurrentlySelected) {
        dataLayer.push({
          event: 'removeFromCart',
          ecommerce: {
            currencyCode: 'EUR',
            remove: {
              products: [serviceDescriptor(service, service.cantidad)],
            },
          },
        })
      }
    }
  })
}

export const checkoutPago = (paymentType: 'total' | 'parcial' | 'taller') => {
  if (paymentType) {
    dataLayer.push({
      event: 'checkout_pago',
      ecommerce: {
        checkout: {
          actionField: {
            step: 7,
            checkout_option: paymentType === 'total' ? 'total' : 'reserva',
          },
        },
      },
    })
    return
  }
  dataLayer.push({
    event: 'checkout_pago',
    ecommerce: {
      checkout: {
        actionField: { step: 7 },
      },
    },
  })
}
const getCategory = (tipoProducto) => {
  switch (tipoProducto) {
    case 'neumatico':
      return 'Neumáticos'
    case 'bateria':
      return 'Batería'
    case 'aceite':
      return 'Aceite'
    default:
      return 'Revisiones'
  }
}
export const finalPaymentReport = (
  cestaState: CestaState,
  userInfo: UserState
) => {
  const collectProductServices = (productos: Product[]): Service[] => {
    const services = productos.map((p) => p.servicios)
    return [].concat(...services)
  }

  errorHandler(() => {
    dataLayer.push({
      event: 'purchase',
      ecommerce: {
        purchase: {
          actionField: {
            id: cestaState.orderNumber,
            revenue: selectImporteTotal(cestaState),
          },
          products: [
            ...cestaState.products.map((item) => ({
              name: item.nombre_producto_es,
              id: item.id_navision,
              price:
                Math.round(
                  ((item.importe - item.promocion.importePromocion) /
                    parseInt(item.cantidad)) *
                    100
                ) / 100,
              brand:
                item.tipo_producto === 'neumatico'
                  ? getMarca(item.nombre_producto_es)
                  : item.descripcion_es,
              category: getCategory(item.tipo_producto),
              quantity: Number(item.cantidad),
              coupon: '',
            })),
            ...collectProductServices(cestaState.products).map((item) => ({
              name: item.nombre_producto_es,
              id: item.id_navision,
              price: item.importe,
              brand: getShortCompany(),
              category: 'Servicio',
              quantity: item.cantidad,
              coupon: '',
            })),
            ...cestaState.services
              .filter((s) => s.selected)
              .map((item) => ({
                name: item.nombre_producto_es,
                id: item.id_navision,
                price: item.importe,
                brand: getShortCompany(),
                category: 'Servicio',
                quantity: 1,
                coupon: '',
              })),
          ].filter((item) => !!item),
        },
      },
    })

    dataLayer.push({
      userData: {
        email: userInfo.email,
        phone_number: userInfo.phone,
        address: {
          first_name: userInfo.userData.name,
          last_name: userInfo.userData.surname,
          street: userInfo.addressData.address,
          city: userInfo.addressData.city,
          country: process.env.GATSBY_LOCALE === 'pt' ? 'Portugal' : 'España',
          postal_code: userInfo.addressData.postCode,
        },
      },
    })
  })
}

export const updateServerSideChanges = (
  cestaState: CestaState,
  oldProducts: Product[],
  newProducts: Product[]
) => {
  const productsDeleted = oldProducts.filter(
    (oldProduct) =>
      !newProducts.some(
        (newProduct) => oldProduct.id_navision === newProduct.id_navision
      )
  )

  if (productsDeleted.length) {
    productsDeleted.forEach((product) => {
      updateQuantity(
        cestaState,
        product.id_navision,
        product.cantidad,
        'remove'
      )
    })
  }
}
